/**
 * Created by hzr on 2015/5/10.
 *
 * 1. 构造安全的执行环境：
 *  a. 向服务端请求timestamp和sign
 *  b. 将timestamp和sign计入到cookie中
 * 2. 执行相应的请求
 *  a. 普通的请求
 *  b. eql
 */
if (!this.JSON) {
    this.JSON = {}
}
(function() {
    if (!Function.prototype.bind) {
        Function.prototype.bind = function(obj) {
            var self = this,
                args = arguments;
            return function() {
                self.apply(obj, Array.prototype.slice.call(args, 1));
            }
        }
    }
    if (!String.prototype.trim) {
        String.prototype.trim = function() {
            return this.replace(/^\s*|\s*$/g, "")
        }
    }
    if (!String.prototype.isBlank) {
        String.prototype.isBlank = function() {
            return this.trim() === "";
        }
    }
    if (!String.prototype.startsWith) {
        String.prototype.startWith = function(str) {
            var reg = new RegExp("^" + str);
            return reg.test(this);
        };
    }
    if (!String.prototype.endsWith) {
        String.prototype.endWith = function(str) {
            var reg = new RegExp(str + "$");
            return reg.test(this);
        }
    }
})();
(function() {
    var class2type = {};
    var toString = class2type.toString;
    var hasOwn = class2type.hasOwnProperty;
    var support = {};
    var TYPES = {
        'undefined': 'undefined',
        'number': 'number',
        'boolean': 'boolean',
        'string': 'string',
        '[object Function]': 'function',
        '[object RegExp]': 'regexp',
        '[object Array]': 'array',
        '[object Date]': 'date',
        '[object File]': 'file',
        '[object FormData]': 'formData',
        '[object Blob]': 'blob',
        '[object Error]': 'error'
    };
    var NODE_TYPE_ELEMENT = 1;

    window.SOP = !!window.SOP || function() {
        return new SOP.fn.init();
    }

    SOP.fn = SOP.prototype = {
        constructor: SOP,
        init: function() {
            console.log("SOP js sdk is initializing...");
        }
    };

    SOP.extend = SOP.fn.extend = function() {
        var src, copyIsArray, copy, name, options, clone,
            target = arguments[0] || {},
            i = 1,
            length = arguments.length,
            deep = false;

        // Handle a deep copy situation
        if (typeof target === "boolean") {
            deep = target;

            // skip the boolean and the target
            target = arguments[i] || {};
            i++;
        }

        // Handle case when target is a string or something (possible in deep copy)
        if (typeof target !== "object" && !SOP.isFunction(target)) {
            target = {};
        }

        // extend SOP itself if only one argument is passed
        if (i === length) {
            target = this;
            i--;
        }

        for (; i < length; i++) {
            // Only deal with non-null/undefined values
            if ((options = arguments[i]) != null) {
                // Extend the base object
                for (name in options) {
                    src = target[name];
                    copy = options[name];

                    // Prevent never-ending loop
                    if (target === copy) {
                        continue;
                    }

                    // Recurse if we're merging plain objects or arrays
                    if (deep && copy && (SOP.isPlainObject(copy) || (copyIsArray = SOP.isArray(copy)))) {
                        if (copyIsArray) {
                            copyIsArray = false;
                            clone = src && SOP.isArray(src) ? src : [];

                        } else {
                            clone = src && SOP.isPlainObject(src) ? src : {};
                        }

                        // Never move original objects, clone them
                        target[name] = SOP.extend(deep, clone, copy);

                        // Don't bring in undefined values
                    } else if (copy !== undefined) {
                        target[name] = copy;
                    }
                }
            }
        }

        // Return the modified object
        return target;
    };

    SOP.copy = SOP.fn.copy = function(obj) {
        return SOP.extend(true, {}, obj);
    };

    SOP.shallowCopy = SOP.fn.shallowCopy = function(src, dst) {
        if (SOP.isArray(src)) {
            dst = dst || [];

            for (var i = 0, ii = src.length; i < ii; i++) {
                dst[i] = src[i];
            }
        } else if (SOP.isObject(src)) {
            dst = dst || {};

            for (var key in src) {
                if (!(key.charAt(0) === "$" && key.charAt(1) === "$")) {
                    dst[key] = src[key];
                }
            }
        }
        return dst || src;
    };

    SOP.extend({

        error: function(msg) {
            throw new Error(msg);
        },

        noop: function() {},

        // See test/unit/core.js for details concerning isFunction.
        // Since version 1.3, DOM methods and functions like alert
        // aren't supported. They return false on IE (#2968).
        isFunction: function(obj) {
            return SOP.type(obj) === "function";
        },

        isUndefined: function(obj) {
            return obj === undefined;
        },

        isDefined: function(obj) {
            return obj !== undefined;
        },

        isWindow: function(obj) {
            /* jshint eqeqeq: false */
            return obj != null && obj == obj.window;
        },

        isString: function(obj) {
            return SOP.type(obj) === "string";
        },

        isArray: Array.isArray || function(obj) {
            return SOP.type(obj) === "array";
        },

        /**
         * @private
         * @param {*} obj
         * @return {boolean} Returns true if `obj` is an array or array-like object (NodeList, Arguments,
         *                   String ...)
         */
        isArrayLike: Array.isArrayLike || function isArrayLike(obj) {
            if (obj == null || SOP.isWindow(obj)) {
                return false;
            }

            // Support: iOS 8.2 (not reproducible in simulator)
            // "length" in obj used to prevent JIT error (gh-11508)
            var length = "length" in Object(obj) && obj.length;

            if (obj.nodeType === NODE_TYPE_ELEMENT && length) {
                return true;
            }

            return SOP.isString(obj) || SOP.isArray(obj) || length === 0 ||
                typeof length === "number" && length > 0 && (length - 1) in obj;
        },

        isNumeric: function(obj) {
            // parseFloat NaNs numeric-cast false positives (null|true|false|"")
            // ...but misinterprets leading-number strings, particularly hex literals ("0x...")
            // subtraction forces infinities to NaN
            // adding 1 corrects loss of precision from parseFloat (#15100)
            return !SOP.isArray(obj) && (obj - parseFloat(obj) + 1) >= 0;
        },

        isDate: function(obj) {
            return obj instanceof Date;
        },

        isEmptyObject: function(obj) {
            var name;
            for (name in obj) {
                return false;
            }
            return true;
        },

        isObject: function(obj) {
            return SOP.type(obj) === "object";
        },

        isFile: function(obj) {
            return SOP.type(obj) === "file";
        },

        isFormData: function(obj) {
            return SOP.type(obj) === "formData]";
        },

        isBlob: function(obj) {
            return SOP.type(obj) === "blob";
        },

        isPlainObject: function(obj) {
            var key;

            // Must be an Object.
            // Because of IE, we also have to check the presence of the constructor property.
            // Make sure that DOM nodes and window objects don't pass through, as well
            if (!obj || SOP.type(obj) !== "object" || obj.nodeType || SOP.isWindow(obj)) {
                return false;
            }

            try {
                // Not own constructor property must be Object
                if (obj.constructor && !hasOwn.call(obj, "constructor") && !hasOwn.call(obj.constructor.prototype, "isPrototypeOf")) {
                    return false;
                }
            } catch (e) {
                // IE8,9 Will throw exceptions on certain host objects #9897
                return false;
            }

            // Support: IE<9
            // Handle iteration over inherited properties before own properties.
            if (support.ownLast) {
                for (key in obj) {
                    return hasOwn.call(obj, key);
                }
            }

            // Own properties are enumerated firstly, so to speed up,
            // if last one is own, then all properties are own.
            for (key in obj) {}

            return key === undefined || hasOwn.call(obj, key);
        },

        type: function(obj) {
            if (obj == null) {
                return obj + "";
            }
            return TYPES[typeof obj] || TYPES[toString.call(obj)] || (obj ? "object" : "null");
        }
    });
})();
/**
 * SOP配置
 */
(function() {
    SOP.settings = {
        ajax: "sop",
        debug: false
    };
})();
(function() {

    var SOPModules;
    var head = document.head || document.getElementsByClassName("head")[0];
    var body = document.body || document.getElementsByClassName("body")[0];

    // 检查flash是否已经安装
    var isFlashInstalled = function() {
        var ret;
        if (SOP.isDefined(this.isFlashInstalledMemo)) {
            return this.isFlashInstalledMemo;
        }
        if (SOP.isDefined(ActiveXObject)) {
            try {
                var ieObj = new ActiveXObject("ShockwaveFlash.ShockwaveFlash");
            } catch (e) {}
            ret = (null != ieObj);
        } else {
            var plugin = navigator.mimeTypes["application/x-shockwave-flash"];
            ret = (null != plugin) && (null != plugin.enabledPlugin);
        }
        this.isFlashInstalledMemo = ret;
        return ret;
    };

    /**
     * @structure
     * jsConfig = {
     *      "description": "a js file's config",
     *      "type":"object",
     *      "properties":{
     *           "url":{"type":"string","required":true},
     *           "id":{"type":"string","required":false},
     *           "charset":{"type":"string","required":false},
     *           "defer":{"type":"boolean","required":false},
     *      }
     * }
     * @param jsConfig
     * @returns {HTMLElement}
     * @private
     */
    var _createScript = function(jsConfig) {

        var script = document.createElement("script");

        script.type = "text/javascript";
        script.src = jsConfig["url"];
        script.setAttribute("charset", jsConfig["charset"] || "utf-8");
        if (SOP.isDefined(jsConfig["id"])) {
            script.setAttribute("id", jsConfig["id"]);
        }
        if (SOP.isDefined(jsConfig["defer"])) {
            script.setAttribute("defer", jsConfig["defer"]);
        }

        return script;
    };
    /**
     * @structure
     * cssConfig = {
     *      "description": "a css file's config",
     *      "type":"object",
     *      "properties":{
     *           "url":{"type":"string","required":true},
     *           "id":{"type":"string","required":false},
     *      }
     * }
     * @param _createCss
     * @returns {HTMLElement}
     * @private
     */
    var _createCss = function(cssConfig) {

        var css = document.createElement("link");

        css.type = "text/css";
        css.href = cssConfig["url"];
        css.rel = "stylesheet";
        if (SOP.isDefined(cssConfig["id"])) {
            css.setAttribute("id", cssConfig["id"]);
        }

        return css;
    };

    // var _createFlash = function(flashConfig) {
    //
    //     var object = document.createElement("object");
    //     if (SOP.isDefined(flashConfig["id"])) {
    //         object.setAttribute("id", flashConfig["id"]);
    //     }
    //
    //     if (window.ActiveXObject && !isFlashInstalled()) {
    //
    //         /* browser supports ActiveX
    //          * create object element with download URL or IE OCX
    //          * */
    //         object.classid = "clsid:D27CDB6E-AE6D-11cf-96B8-444553540000";
    //         object.codebase = "http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=8,5,0,0";
    //     } else {
    //
    //         // browser supports Netscape Plugin API
    //         if (SOP.isDefined(flashConfig["url"])) {
    //             object.data = flashConfig["url"];
    //         }
    //         object.type = "application/x-shockwave-flash";
    //
    //         object.height = flashConfig["height"] || 0;
    //         object.width = flashConfig["width"] || 0;
    //
    //         var movie = document.createElement("param");
    //         movie.name = "movie";
    //         if (SOP.isDefined(flashConfig["url"])) {
    //             movie.value = flashConfig["url"];
    //         }
    //         var quality = document.createElement("param");
    //         quality.name = "quality";
    //         quality.value = "high";
    //         var swliveconnect = document.createElement("param");
    //         swliveconnect.name = "swliveconnect";
    //         swliveconnect.value = "true";
    //
    //         object.appendChild(movie);
    //         object.appendChild(quality);
    //         object.appendChild(swliveconnect);
    //
    //         var pluginurl = document.createElement("param");
    //         pluginurl.name = "pluginurl";
    //         pluginurl.value = "http://www.macromedia.com/go/getflashplayer";
    //         var pluginspage = document.createElement("param");
    //         pluginspage.name = "pluginspage";
    //         pluginspage.value = "http://www.macromedia.com/go/getflashplayer";
    //
    //         object.appendChild(pluginurl);
    //         object.appendChild(pluginspage);
    //     }
    //
    //     return object;
    // };

    var _load = function(name, callBackQueue) {
        if (false === SOPModules[name].isLoad) {

            var url = SOPModules[name].url;
            var pos1 = url.lastIndexOf(".") + 1;
            var pos2 = url.indexOf("?");
            var fileType = url.substring(pos1, pos2 == -1 ? url.length : pos2);

            var file;
            if ("js" === fileType) {
                file = _createScript(SOPModules[name]);
            } else if ("css" === fileType) {
                file = _createCss(SOPModules[name]);
            } else if ("swf" === fileType) {
                // file = _createFlash(SOPModules[name]);
            } else {
                throw new Error("模块[" + name + "]url错误");
            }

            file.onload = file.onreadystatechange = function() {
                if (!this.readyState || this.readyState === "loaded" || this.readyState === "complete") {
                    SOPModules[name].isLoad = true;
                    for (var i = 0, len = callBackQueue.length; i < len; i++) {
                        if (SOP.isFunction(callBackQueue[i])) callBackQueue[i]();
                    }
                }
            }

            if ("js" === fileType || "css" === fileType) {

                head.appendChild(file);
            } else if ("swf" === fileType) {

                body.appendChild(file);
            }
        }
    };

    // 模块定义
    SOP.define = function(name, config) {

        if (SOP.isUndefined(SOPModules)) {
            SOPModules = {};
        }
        if (SOP.isDefined(SOPModules[name])) {
            SOP.log("模块已定义");
            return;
        }
        SOPModules[name] = config;
        // 是否加载过
        SOPModules[name].isLoad = false;
        // 是否使用过
        SOPModules[name].isUse = false;
        // 回调队列
        SOPModules[name].callBackQueue = [];
        return this;
    };

    // 模块使用
    SOP.use = function(name, func) {

        if (SOP.isUndefined(SOPModules[name])) {
            SOP.log("模块[" + name + "]不存在");
            throw new Error("模块[" + name + "]不存在");
        }
        // 回调队列，用于多次use同一个模块时的多个回调
        var callBackQueue = SOPModules[name].callBackQueue;
        if (false === SOPModules[name].isUse) {
            SOPModules[name].isUse = true;
            // 推入队列
            callBackQueue.push(func);

            var requires = SOPModules[name].requires;

            // 串行依赖
            if (SOP.isString(requires)) {
                this.use(requires, function() {
                    _load(name, callBackQueue);
                });
                return this;
            }

            // 并行依赖
            if (SOP.isArray(requires)) {
                var len = requires.length;
                SOPModules[name].count = len;
                for (var i = 0; i < len; i++) {
                    this.use(requires[i], function() {
                        SOPModules[name].count--;
                        // 串行依赖即：等待所有的文件加载完毕之后才执行回调
                        if (SOPModules[name].count === 0) {
                            _load(name, callBackQueue);
                        }
                    });
                }
                return this;
            }
            _load(name, callBackQueue);
        } else {

            // 如果模块已经标记被使用，但是模块还未下载完毕时，加入队列，如果下载完毕则直接指定回调函数
            if (false === SOPModules[name].isLoad) {
                if (SOP.isFunction(func)) {
                    callBackQueue.push(func);
                }
            } else {
                if (SOP.isFunction(func)) {
                    func();
                }
            }

            return this;
        }
    };
})();
(function() {
    SOP.extend({
        forEach: function(obj, iterator, context) {
            var key, length;
            if (obj) {
                if (SOP.isFunction(obj)) {
                    for (key in obj) {
                        // Need to check if hasOwnProperty exists,
                        // as on IE8 the result of querySelectorAll is an object without a hasOwnProperty function
                        if (key != 'prototype' && key != 'length' && key != 'name' && (!obj.hasOwnProperty || obj.hasOwnProperty(key))) {
                            iterator.call(context, obj[key], key, obj);
                        }
                    }
                } else if (SOP.isArray(obj) || SOP.isArrayLike(obj)) {
                    var isPrimitive = typeof obj !== 'object';
                    for (key = 0, length = obj.length; key < length; key++) {
                        if (isPrimitive || key in obj) {
                            iterator.call(context, obj[key], key, obj);
                        }
                    }
                } else if (obj.forEach && obj.forEach !== forEach) {
                    obj.forEach(iterator, context, obj);
                } else {
                    for (key in obj) {
                        if (obj.hasOwnProperty(key)) {
                            iterator.call(context, obj[key], key, obj);
                        }
                    }
                }
            }
            return obj;
        }
    });
})();
(function() {
    var JSON_START = /^\[|^\{(?!\{)/;
    var JSON_ENDS = {
        '[': /]$/,
        '{': /}$/
    };
    SOP.extend({
        parseJSON: function(source) {
            var unescaped = source.trim().replace(/"(\\.|[^"\\])*"/g, "");
            if (/[^,:{}\[\]0-9\.\-+Eaeflnr-u \n\r\t]/.test(unescaped)) {
                throw new SyntaxError("Unexpected token:" + source);
            }
            return (new Function("return " + source))();
        },
        stringifyJSON: function(source, depth) {
            var rv = [];
            var depth = depth || 0;

            if (depth >= this.JSON_MAX_DEPTH) {
                throw new Error("The JSON object is to deep.");
            }

            // "null" or "undefined"
            if (null === source || undefined === source) {
                return source + "";
            }

            if (source["toJSON"]) {
                return source["toJSON"];
            }

            var type = typeof source;

            if (type === "boolean" || type === "number") {
                return source + "";
            }
            if (type === "string") {
                return '"' + this.escape(source) + '"';
            }

            var brackets = ["{", "}"];

            if (this.isArray(source)) {
                brackets = ["[", "]"];
                for (var i = 0, iz = source.length; i < iz; i++) {
                    rv.push(this.stringifyJSON(source[i], depth + 1));
                }
            } else if (source.constructor === ({}).constructor) {
                for (var key in source) {
                    if (source.hasOwnProperty(key)) {
                        rv.push('"' + this.escape(key) + '":' + this.stringifyJSON(source[key], depth + 1));
                    }
                }
            }

            return brackets[0] + rv.join(",") + brackets[1];
        },
        fromJson: function(json) {
            return SOP.isString(json) ?
                JSON.parse(json) :
                json;
        },
        toJson: function(obj, pretty) {
            if (typeof obj === "undefined") return undefined;
            if (!SOP.isNumeric(pretty)) {
                pretty = pretty ? 2 : null;
            }
            return JSON.stringify(obj, SOP.toJsonReplacer, pretty);
        },
        escape: function(str) {
            var JSON_ESCAPE = {
                '\b': '\\b', // backspace        U+0008
                '\t': '\\t', // tab              U+0009
                '\n': '\\n', // line feed        U+000A
                '\f': '\\f', // form feed        U+000C
                '\r': '\\r', // carriage return  U+000D
                '"': '\\"', // quotation mark   U+0022
                '\\': '\\\\' // reverse solidus  U+005C
            };
            return str.replace(/(?:[\b\t\n\f\r"]|\\)/g, function(_) {
                return JSON_ESCAPE[_];
            }).replace(/(?:[\x00-\x1f])/g, function(_) {
                return "\\u00" + ("0" + _.charCodeAt(0).toString(16)).slice(-2);
            });
        },
        toJsonReplacer: function(key, value) {
            var val = value;
            if (typeof key === "string" && key.charAt(0) === "$" && key.charAt(1) === "$") {
                val = undefined;
            } else if (SOP.isWindow(value)) {
                val = '$WINDOW';
            } else if (value && document === value) {
                val = '$DOCUMENT';
            }
            return val;
        },
        isJsonLike: function(str) {
            var jsonStart = str.match(JSON_START);
            return jsonStart && JSON_ENDS[jsonStart[0]].test(str);
        },
        JSON_MAX_DEPTH: 512
    });
})();
(function() {
    // 编码相关工具
    var base64map = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
    SOP.extend({

        // Bit-wise rotate left
        rotl: function(n, b) {
            return (n << b) | (n >>> (32 - b));
        },

        // Bit-wise rotate right
        rotr: function(n, b) {
            return (n << (32 - b)) | (n >>> b);
        },

        // Swap big-endian to little-endian and vice versa
        endian: function(n) {

            // If number given, swap endian
            if (n.constructor == Number) {
                return util.rotl(n, 8) & 0x00FF00FF |
                    util.rotl(n, 24) & 0xFF00FF00;
            }

            // Else, assume array and swap all items
            for (var i = 0; i < n.length; i++)
                n[i] = util.endian(n[i]);
            return n;

        },

        // Generate an array of any length of random bytes
        randomBytes: function(n) {
            for (var bytes = []; n > 0; n--)
                bytes.push(Math.floor(Math.random() * 256));
            return bytes;
        },

        // Convert a string to a byte array
        stringToBytes: function(str) {
            var bytes = [];
            for (var i = 0; i < str.length; i++)
                bytes.push(str.charCodeAt(i));
            return bytes;
        },

        // Convert a byte array to a string
        bytesToString: function(bytes) {
            var str = [];
            for (var i = 0; i < bytes.length; i++)
                str.push(String.fromCharCode(bytes[i]));
            return str.join("");
        },

        // Convert a string to big-endian 32-bit words
        stringToWords: function(str) {
            var words = [];
            for (var c = 0, b = 0; c < str.length; c++, b += 8)
                words[b >>> 5] |= str.charCodeAt(c) << (24 - b % 32);
            return words;
        },

        // Convert a byte array to big-endian 32-bits words
        bytesToWords: function(bytes) {
            var words = [];
            for (var i = 0, b = 0; i < bytes.length; i++, b += 8)
                words[b >>> 5] |= bytes[i] << (24 - b % 32);
            return words;
        },

        // Convert big-endian 32-bit words to a byte array
        wordsToBytes: function(words) {
            var bytes = [];
            for (var b = 0; b < words.length * 32; b += 8)
                bytes.push((words[b >>> 5] >>> (24 - b % 32)) & 0xFF);
            return bytes;
        },

        // Convert a byte array to a hex string
        bytesToHex: function(bytes) {
            var hex = [];
            for (var i = 0; i < bytes.length; i++) {
                hex.push((bytes[i] >>> 4).toString(16));
                hex.push((bytes[i] & 0xF).toString(16));
            }
            return hex.join("");
        },

        // Convert a hex string to a byte array
        hexToBytes: function(hex) {
            var bytes = [];
            for (var c = 0; c < hex.length; c += 2)
                bytes.push(parseInt(hex.substr(c, 2), 16));
            return bytes;
        },

        // Convert a byte array to a base-64 string
        bytesToBase64: function(bytes) {

            // Use browser-native function if it exists
            if (typeof btoa == "function") return btoa(SOP.bytesToString(bytes));

            var base64 = [],
                overflow;

            for (var i = 0; i < bytes.length; i++) {
                switch (i % 3) {
                    case 0:
                        base64.push(base64map.charAt(bytes[i] >>> 2));
                        overflow = (bytes[i] & 0x3) << 4;
                        break;
                    case 1:
                        base64.push(base64map.charAt(overflow | (bytes[i] >>> 4)));
                        overflow = (bytes[i] & 0xF) << 2;
                        break;
                    case 2:
                        base64.push(base64map.charAt(overflow | (bytes[i] >>> 6)));
                        base64.push(base64map.charAt(bytes[i] & 0x3F));
                        overflow = -1;
                }
            }

            // Encode overflow bits, if there are any
            if (overflow != undefined && overflow != -1)
                base64.push(base64map.charAt(overflow));

            // Add padding
            while (base64.length % 4 != 0) base64.push("=");

            return base64.join("");
        },

        // Convert a base-64 string to a byte array
        base64ToBytes: function(base64) {

            // Use browser-native function if it exists
            if (typeof atob == "function") return util.stringToBytes(atob(base64));

            // Remove non-base-64 characters
            base64 = base64.replace(/[^A-Z0-9+\/]/ig, "");

            var bytes = [];

            for (var i = 0; i < base64.length; i++) {
                switch (i % 4) {
                    case 1:
                        bytes.push((base64map.indexOf(base64.charAt(i - 1)) << 2) |
                            (base64map.indexOf(base64.charAt(i)) >>> 4));
                        break;
                    case 2:
                        bytes.push(((base64map.indexOf(base64.charAt(i - 1)) & 0xF) << 4) |
                            (base64map.indexOf(base64.charAt(i)) >>> 2));
                        break;
                    case 3:
                        bytes.push(((base64map.indexOf(base64.charAt(i - 1)) & 0x3) << 6) |
                            (base64map.indexOf(base64.charAt(i))));
                        break;
                }
            }

            return bytes;
        }
    });
})();
(function() {
    // base64
    // private property
    var _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    // private method for UTF-8 encoding
    var _utf8_encode = function(string) {
        string = string.replace(/\r\n/g, "\n");
        var utftext = "";

        for (var n = 0; n < string.length; n++) {

            var c = string.charCodeAt(n);

            if (c < 128) {
                utftext += String.fromCharCode(c);
            } else if ((c > 127) && (c < 2048)) {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
            } else {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
            }

        }

        return utftext;
    };
    // private method for UTF-8 decoding
    var _utf8_decode = function(utftext) {
        var string = "";
        var i = 0;
        var c = c1 = c2 = 0;

        while (i < utftext.length) {

            c = utftext.charCodeAt(i);

            if (c < 128) {
                string += String.fromCharCode(c);
                i++;
            } else if ((c > 191) && (c < 224)) {
                c2 = utftext.charCodeAt(i + 1);
                string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                i += 2;
            } else {
                c2 = utftext.charCodeAt(i + 1);
                c3 = utftext.charCodeAt(i + 2);
                string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                i += 3;
            }
        }

        return string;
    };
    SOP.extend({
        // public method for encoding
        encode: function(input) {
            var output = "";
            var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
            var i = 0;

            input = _utf8_encode(input);

            while (i < input.length) {

                chr1 = input.charCodeAt(i++);
                chr2 = input.charCodeAt(i++);
                chr3 = input.charCodeAt(i++);

                enc1 = chr1 >> 2;
                enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                enc4 = chr3 & 63;

                if (isNaN(chr2)) {
                    enc3 = enc4 = 64;
                } else if (isNaN(chr3)) {
                    enc4 = 64;
                }

                output = output +
                    _keyStr.charAt(enc1) + _keyStr.charAt(enc2) +
                    _keyStr.charAt(enc3) + _keyStr.charAt(enc4);
            }

            return output;
        },

        // public method for decoding
        decode: function(input) {
            var output = "";
            var chr1, chr2, chr3;
            var enc1, enc2, enc3, enc4;
            var i = 0;

            input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

            while (i < input.length) {

                enc1 = _keyStr.indexOf(input.charAt(i++));
                enc2 = _keyStr.indexOf(input.charAt(i++));
                enc3 = _keyStr.indexOf(input.charAt(i++));
                enc4 = _keyStr.indexOf(input.charAt(i++));

                chr1 = (enc1 << 2) | (enc2 >> 4);
                chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
                chr3 = ((enc3 & 3) << 6) | enc4;

                output = output + String.fromCharCode(chr1);

                if (enc3 != 64) {
                    output = output + String.fromCharCode(chr2);
                }
                if (enc4 != 64) {
                    output = output + String.fromCharCode(chr3);
                }

            }

            output = _utf8_decode(output);

            return output;
        }
    });
})();
(function() {
    // 加密相关功能
    // Public API
    var SHA1 = function(message, options) {
        var digestbytes = SOP.wordsToBytes(SHA1._sha1(message));
        return options && options.asBytes ? digestbytes :
            options && options.asString ? SOP.bytesToString(digestbytes) :
            SOP.bytesToHex(digestbytes);
    };

    // The core
    SHA1._sha1 = function(message) {

        var m = SOP.stringToWords(message),
            l = message.length * 8,
            w = [],
            H0 = 1732584193,
            H1 = -271733879,
            H2 = -1732584194,
            H3 = 271733878,
            H4 = -1009589776;

        // Padding
        m[l >> 5] |= 0x80 << (24 - l % 32);
        m[((l + 64 >>> 9) << 4) + 15] = l;

        for (var i = 0; i < m.length; i += 16) {

            var a = H0,
                b = H1,
                c = H2,
                d = H3,
                e = H4;

            for (var j = 0; j < 80; j++) {

                if (j < 16) w[j] = m[i + j];
                else {
                    var n = w[j - 3] ^ w[j - 8] ^ w[j - 14] ^ w[j - 16];
                    w[j] = (n << 1) | (n >>> 31);
                }

                var t = ((H0 << 5) | (H0 >>> 27)) + H4 + (w[j] >>> 0) + (
                    j < 20 ? (H1 & H2 | ~H1 & H3) + 1518500249 :
                    j < 40 ? (H1 ^ H2 ^ H3) + 1859775393 :
                    j < 60 ? (H1 & H2 | H1 & H3 | H2 & H3) - 1894007588 :
                    (H1 ^ H2 ^ H3) - 899497514);

                H4 = H3;
                H3 = H2;
                H2 = (H1 << 30) | (H1 >>> 2);
                H1 = H0;
                H0 = t;

            }

            H0 += a;
            H1 += b;
            H2 += c;
            H3 += d;
            H4 += e;

        }

        return [H0, H1, H2, H3, H4];
    };
    // Package private blocksize
    SHA1._blocksize = 16;

    SOP.extend({
        // Package private blocksize
        HMAC: function(hasher, message, key, options) {

            // Allow arbitrary length keys
            key = key.length > hasher._blocksize * 4 ?
                hasher(key, { asBytes: true }) :
                SOP.stringToBytes(key);

            // XOR keys with pad constants
            var okey = key,
                ikey = key.slice(0);
            for (var i = 0; i < hasher._blocksize * 4; i++) {
                okey[i] ^= 0x5C;
                ikey[i] ^= 0x36;
            }

            var hmacbytes = hasher(SOP.bytesToString(okey) +
                hasher(SOP.bytesToString(ikey) + message, { asString: true }), { asBytes: true });
            return options && options.asBytes ? hmacbytes :
                options && options.asString ? SOP.bytesToString(hmacbytes) :
                SOP.bytesToHex(hmacbytes);
        },
        SHA1: SHA1
    });
})();
(function() {
    var devMode = (function() {
        var devMode = false,
            hash = location.hash;
        if (0 == hash.indexOf("#")) {
            hash = hash.substr(1);
            devMode = ("&" + hash + "&").indexOf("&dev=true&") > -1;
        }
        return devMode;
    })();
    var path = (function() {
        var pathname = location.pathname;
        var index = pathname.lastIndexOf("/");
        if (-1 === index) {
            return pathname;
        } else {
            return pathname.substr(index + 1);
        }
    })();
    SOP.extend({
        log: function(message, level) {
            level = level || "log";
            if ((devMode || (SOP.settings && SOP.settings.debug == true)) && (SOP.isDefined(console))) {
                var content = message + (path ? " (" + path + ")" : "");
                console[level] && console[level](content);
            }
        },
        debug: function(message) {
            SOP.log(message, "debug");
        },
        guid: function(prefix) {
            prefix = prefix || "e";
            return prefix + (Math.random() * (1 << 30)).toString(16).replace(".", "")
        },
        trim: function(str) {
            return str.trim();
        },
        param: function(param, separator, assign) {

            if (!SOP.isPlainObject(param) && !SOP.isString(param)) {
                return "";
            }

            separator = separator || "&";
            assign = assign || "=";
            if (SOP.isString(param)) {
                param = SOP.unParam(param, separator, assign);
            }
            var ret = [];

            for (var key in param) {
                var value = param[key];
                if (null !== value && SOP.isDefined(value) && !SOP.isFunction(value)) {
                    ret.push(encodeURIComponent(key) + assign + encodeURIComponent(value));
                }
            }
            ret.sort();
            return ret.join(separator);
        },
        unParam: function(param, separator, assign) {

            var ret = {};

            separator = separator || "&";
            assign = assign || "=";

            var params = param.split(separator);
            for (var i = 0, len = params.length; i < len; i++) {
                var pair = SOP.trim(param[i]).split(assign);
                if (pair && pair.length == 2) {
                    ret[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
                }
            }
            return ret;
        }
    });
})();
(function() {
    SOP.extend({
        parseURL: function(url) {
            var parser = document.createElement("a");
            // "http://example.com:3000/pathname/?search=test#hash";
            parser.href = url;
            return {
                protocol: parser.protocol, // "http:"
                host: parser.host, // => "example.com:3000"
                hostname: parser.hostname, // => "example.com"
                port: parser.port, // => "3000"
                // fixme:guowen  parser.pathname in ie is first letter is not '/'
                pathname: parser.pathname.indexOf('/') !== 0 ? '/' + parser.pathname : parser.pathname, // => "/pathname/"
                hash: parser.hash, // => "#hash"
                search: parser.search, // => "?search=test"
                origin: parser.origin || location.origin // fixme:guowen  parser.origin in ie&edge is undeifuned
                // =>
                // "http://example.com:3000"
            }
        },
        toFullURL: function(url) {
            var urlParts = SOP.parseURL(url);
            return urlParts.origin + urlParts.pathname + urlParts.search + urlParts.hash;
        }
    });
})();
(function() {
    /* dom操作 */
    SOP.extend({
        $: function() {
            var elements = [];
            for (var i = 0, len = arguments.length; i < len; i++) {
                var element = arguments[i];
                if (SOP.isString(element)) {
                    element = document.getElementById(element);
                }
                if (1 === len) {
                    return element;
                }
                elements.push(element);
            }
            return elements;
        }
    });
})();
(function() {
    var JSON_PROTECTION_PREFIX = /^\)\]\}',?\n/;
    var APPLICATION_JSON = "application/json";
    var CONTENT_TYPE_APPLICATION_JSON = { "Content-Type": APPLICATION_JSON + ";charset=utf-8" };
    var doc = document;
    var head = document.getElementsByTagName("head")[0];

    var removeScript = function(script, callback) {
        var _callback = callback;
        callback = function() {
            _callback && _callback();
            script.parentNode.removeChild(script);
        }
        if (doc.addEventListener) {
            doc.addEventListener("load", callback, false);
        } else {
            script.onreadystatechange = function() {
                var state = script.readyState;
                if (/loaded|complete/.test(state)) {
                    callback();
                }
            }
        }
    };

    function defaultHttpResponseTransform(data, headers) {
        if (SOP.isString(data)) {
            // Strip json vulnerability protection prefix and trim whitespace
            var tempData = data.replace(JSON_PROTECTION_PREFIX, "").trim();
            if (tempData) {
                var contentType = headers("Content-Type");
                if ((contentType && (contentType.indexOf(APPLICATION_JSON) === 0)) || SOP.isJsonLike(tempData)) {
                    data = SOP.fromJson(tempData);
                }
            }
        }
        return data;
    };

    SOP.define("pmxdr", {
        url: "https://shejicdn.kuaimai.com/static/pmxdr-client.js"
    });
    SOP.define("libxdr", {
        url: "https://shejicdn.kuaimai.com/static/libxdr.js",
        requires: ["pmxdr"]
    });
    SOP.define("jquery", {
        url: "http://1988-static.oss-cn-hangzhou.aliyuncs.com/js/jquery_1.9.1.min.js"
    })

    SOP.extend({
        ajax: function(url, options, isAsync) {

            if (SOP.isObject(url)) {
                if (typeof options === 'boolean') {
                    isAsync = options
                }
                options = url;
                url = options.url;
            }

            if (typeof isAsync !== 'boolean') {
                isAsync = true
            }

            options = options || {};
            var method = options.method || options.type || "get";
            var data = options.data || {};
            var dataType = options.dataType || "*";
            var dataTypes = SOP.trim(dataType).toLowerCase().match(SOP.rnotwhite) || [""];
            var success = options.success || SOP.noop();
            var useJqueryAjax = SOP.settings && SOP.settings.ajax === "jquery";

            if (SOP.settings && SOP.settings.debug) {
                SOP.log("Using " + SOP.settings.ajax + " ajax")
            }

            if (useJqueryAjax) {

                if (!SOP.isString(data)) {
                    data = SOP.param(data);
                }

                var settings = {
                    url: url,
                    data: data,
                    dataType: dataType,
                    success: success,
                    type: method.toUpperCase(),
                    async: isAsync,
                    contentType: 'application/x-www-form-urlencoded'
                };

                SOP.use("jquery", function() {

                    $.ajax(settings);
                });
            } else {

                var timeout;
                var xhr = new XMLHttpRequest();
                if (window.XMLHttpRequest) {
                    xhr = new XMLHttpRequest();
                } else {
                    xhr = new ActiveXObject("Microsoft.XMLHTTP");
                }

                xhr.onreadystatechange = function() {
                    if (4 == xhr.readyState) { // 请求已经完成，且相应就绪
                        if (200 == xhr.status) {
                            if (timeout) {
                                clearTimeout(timeout);
                            }
                            success && success(JSON.parse(xhr.responseText), xhr);
                        }
                    }
                };
                if (!SOP.isString(data)) {
                    data = SOP.param(data);
                }
                if ("get" === method.toLowerCase()) {
                    url = url + "?" + data;
                    data = null;
                }
                xhr.open(method, url, isAsync);
                xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
                xhr.setRequestHeader("Accept",
                    dataTypes[0] && SOP.accepts[dataTypes[0]] ?
                    SOP.accepts[dataTypes[0]] + (dataTypes[0] !== "*" ? ", " + SOP.allTypes + "; q=0.01" : "") :
                    SOP.accepts["*"]
                );
                // xhr.withCredentials = "true";
                xhr.send(data);
                timeout = setTimeout(function() {
                    xhr.abort();
                    SOP.log("xhr failure", "warning");
                }, 900000);
            }
        },
        get: function(url, data, success, dataType, isAsync) {
            if (typeof dataType === 'boolean') {
                isAsync = dataType
                dataType = undefined
            }
            SOP.ajax({
                url: url,
                data: data,
                type: "get",
                success: success,
                dataType: dataType
            }, isAsync);
        },
        post: function(url, data, success, dataType, isAsync) {
            if (typeof dataType === 'boolean') {
                isAsync = dataType
                dataType = undefined
            }
            SOP.ajax({
                url: url,
                data: data,
                type: "post",
                success: success,
                dataType: dataType
            }, isAsync);
        },
        getScript: function(url, success) {
            var script = doc.createElement("script"),
                guid = SOP.guid();
            script.src = url;
            script.id = guid;
            script.charset = "utf-8";
            success && removeScript(script, success);
            head.appendChild(script);
            return script;
        },
        doJsonpByGet: function(url, param, success) {

            var guid = SOP.guid(),
                cb = "SOP.jsonpCBs." + guid,
                url = url + "?callback=" + cb;
            if (param) {
                url = url + "&" + SOP.param(param);
            }
            if (url.length > 4000) {
                SOP.log("JSONP only support a maximum of 4000 bytes of input.", "error");
                return;
            }
            SOP.jsonpCBs[guid] = function(response) {
                success && success(response);
                delete SOP.jsonpCBs[guid];
            };
            SOP.getScript(url);
        },
        doJsonpByPost: function(url, param, success) {

            SOP.use("libxdr", function() {

                var request = new XDR();
                request.open("POST", url);
                request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
                request.onload = function() {
                    if (success && SOP.isFunction(success)) {

                        success(JSON.parse(this.responseText));
                    }
                };
                request.send(SOP.param(param));
            });

            return false;
        },
        jsonp: function(url, param, success, type) {
            type = (type || "get");
            if (SOP.isString(type)) {
                type = type.toLowerCase();
                if ("get" === type) {
                    SOP.doJsonpByGet(url, param, success);
                } else if ("post" === type) {
                    SOP.doJsonpByPost(url, param, success);
                }
            }
        },
        jsonpCBs: {},
        rnotwhite: (/\S+/g),
        allTypes: "*/".concat("*"),
        accepts: {
            "*": "*/*",
            text: "text/plain",
            html: "text/html",
            xml: "application/xml, text/xml",
            json: "application/json, text/javascript"
        },
        contents: {
            xml: /xml/,
            html: /html/,
            json: /json/
        },
        http: {
            defaults: {
                // transform incoming response data
                transformResponse: [defaultHttpResponseTransform],

                // transform outgoing request data
                transformRequest: [function(d) {
                    return SOP.isObject(d) && !SOP.isFile(d) && !SOP.isBlob(d) && !SOP.isFormData(d) ? SOP.toJson(d) : d;
                }],

                // default headers
                headers: {
                    common: {
                        "Accept": "application/json, text/plain, */*"
                    },
                    post: SOP.shallowCopy(CONTENT_TYPE_APPLICATION_JSON),
                    put: SOP.shallowCopy(CONTENT_TYPE_APPLICATION_JSON),
                    patch: SOP.shallowCopy(CONTENT_TYPE_APPLICATION_JSON)
                },

                xsrfCookieName: "XSRF-TOKEN",
                xsrfHeaderName: "X-XSRF-TOKEN"
            }
        }
    });
})();
(function() {
    // cookie
    var doc = document;
    var millisecondsADay = 24 * 60 * 60 * 1000;
    var isNotBlank = function(str) {
        return SOP.isString(str) && !str.isBlank();
    };
    SOP.extend({
        getCookie: function(name) {
            var ret,
                match;
            if (isNotBlank(name)) {
                name = name.trim();
                if (match = String(doc.cookie).match(new RegExp("(?:^| )" + name + "(?:(?:=([^;]*))|;|$)"))) {
                    ret = match[1] ? decodeURIComponent(match[1]) : "";
                }
            }
            return ret;
        },
        setCookie: function(name, value, options) {

            name = name || "";
            value = value || "";
            options = options || {};
            var expires = options.expires || Number.MAX_VALUE;
            var path = options.path || "";
            var domain = options.domain || "";
            var secure = SOP.isDefined(options.secure) ? options.secure : "";

            value = String(encodeURIComponent(value));
            if (SOP.isNumeric(expires)) {
                var expiresDays = expires; // 以天为单位
                expires = new Date();
                expires.setTime(expires.getTime() + expiresDays * millisecondsADay);
            }
            if (SOP.isDate(expires)) {
                value += "; expires=" + expires.toUTCString();
            }
            if (isNotBlank(domain)) {
                value += "; domain=" + domain;
            }
            if (isNotBlank(path)) {
                value += "; path=" + path;
            }
            if (secure) {
                value += "; secure";
            }
            doc.cookie = name + "=" + value;
        },
        removeCookie: function(name) {
            SOP.setCookie(name, "", {
                expires: -1
            });
        },
        //以全天的时间区间生成accesstoken
        getAccesstoken: function() {
            return localStorage.getItem('accessToken')
        }
    });
})();
(function() {

    var signStore = {
        signing: false,
        success: SOP.noop,
        callQueue: []
    };
    var checkSign = function(success) {
        if (signStore.signing) {
            signStore.callQueue.push(success);
        } else {
            // 借助于cookie_version标示来控制何时清空用户客户端的cookie
            var cookieVersion = SOP.getCookie("cookie_version");
            if (SOP.cookieVersion != cookieVersion) {
                SOP.removeCookie("timestamp");
                SOP.removeCookie("sign");
                SOP.setCookie("cookie_version", SOP.cookieVersion, {
                    expires: SOP.cookieExpires
                });
            }

            var timestamp = SOP.getCookie("timestamp");
            var sign = SOP.getCookie("sign");

            if (!timestamp || !sign) {
                signStore.signing = true;
                // 判断签名的服务是否是本域服务，如果是本域使用普通的get方法，否则使用jsonp
                var localURL = SOP.parseURL(window.location.href);
                var signURL = SOP.parseURL(SOP.signServerUrl);
                var fullSignURL = SOP.toFullURL(SOP.signServerUrl);
                if (localURL.origin === signURL.origin) {
                    SOP.get(fullSignURL, SOP.appKey, function(resp) {
                        signDone(resp, success);
                    }, "json");
                } else {
                    SOP.post(fullSignURL, { appKey: SOP.appKey }, function(resp) {
                        signDone(resp, success);
                    });
                }
            } else {
                signDone(null, success);
            }
        }
    };

    var signDone = function(resp, success) {
        if (resp) {

            SOP.setCookie("timestamp", resp.timestamp, { expires: SOP.cookieExpires });
            SOP.setCookie("sign", resp.sign, { expires: SOP.cookieExpires });

            signStore.signing = false;
            if (SOP.isFunction(success)) {
                success();
            }
            if (signStore.callQueue.length > 0) {
                for (var i in signStore.callQueue) {
                    if (SOP.isFunction(signStore.callQueue[i])) {
                        signStore.callQueue[i]();
                    }
                }
                signStore.callQueue = [];
            }
        } else {
            if (SOP.isFunction(success)) {
                success();
            }
        }
    };

    SOP.extend({
        clients: {},
        cookieExpires: 1 / (60 * 24), // 1分钟
        cookieVersion: "2017-06-25",
        serverUrl: null,
        signServerUrl: null,
        appKey: null,
        getRequestParams: function(request, session) {
            var ret = {
                appKey: SOP.appKey,
                method: request.method,
                version: request.version || "1.0",
                format: request.format || "json",
                locale: "zh_CN",
                session: session || "",
                sessionId: SOP.getCookie("sessionId"),
                timestamp: SOP.getCookie("timestamp"),
                sign: SOP.getCookie("sign"),
                accessToken: SOP.getAccesstoken()
            };
            SOP.extend(ret, (function() {
                var extend = {};
                for (var field in request) {
                    if (!SOP.isFunction(request[field])) {
                        if (SOP.isArray(request[field])) {
                            extend[field] = request[field].join(";");
                        } else {
                            extend[field] = request[field];
                        }
                    }
                }
                return extend;
            })());
            return ret;
        },
        DefaultClient: function(signServerUrl, _serverUrl, _appKey, dev) {

            SOP.signServerUrl = signServerUrl;
            SOP.serverUrl = _serverUrl;
            SOP.appKey = _appKey;

            checkSign();
            var key = (SOP.appKey || "") + (SOP.serverUrl || "");
            if (key != "") {
                SOP.clients[key] = this;
            }
            return this;
        }
    });

    SOP.DefaultClient.prototype = {
        execute: function(request, session, success, isAsync) {
            request = request || {};

            checkSign(function() {

                if (SOP.isFunction(session)) {
                    isAsync = success === false ? false : true
                    success = session;

                }

                var sql;
                if (SOP.isString(request)) {
                    sql = request;
                }
                if (sql) {
                    // sql方式执行
                    // TODO
                } else {

                    // request方式执行
                    // step1. 调用check函数
                    // step2. 拼接参数
                    // step3. 通过jsonp请求数据

                    // step1
                    request.check && request.check();
                    // step2
                    var params = SOP.getRequestParams(request);
                    // step3
                    SOP.post(
                        SOP.serverUrl,
                        params,
                        success,
                        isAsync
                    );
                }
            });
        }
    };
})();
(function() {
    // 文件上传模块
    SOP.define("plupload", {
        url: "https://shejicdn.kuaimai.com/static/plupload.full.min.js"
    });
    var queue = [];
    var jsLoaded = false;
    var client;
    var accessKeyId = "",
        url = "",
        policy = "",
        signature = "",
        expire = 0,
        now = Date.parse(new Date()) / 1000;
    var getSign = function(success) {
        //可以判断当前expire是否超过了当前时间,如果超过了当前时间,就重新取一下(10s 做为缓冲)
        now = Date.parse(new Date()) / 1000;
        if (expire < now + 10) {
            var request = new SOP.OSSSignGetRequest();
            client.execute(request, function(response) {
                var sign = response.sign;
                accessKeyId = sign["accessKeyId"];
                url = sign['url'];
                policy = sign["policy"];
                signature = sign["signature"];
                expire = parseInt(sign["expire"]);
                success(true);
            });
        } else {
            success(false);
        }
    };
    var getUrl = function(key, success) {
        var request = new SOP.FileUrlsGetRequest();
        if (key) {
            request.keys = [];
            request.keys.push(key);
            client.execute(request, function(response) {
                if (response.success) {
                    success(response.fileUrls[0]);
                } else {
                    success();
                }
            });
        }
    };
    var getFileName = function(file) {
        var fileName = file.target_name || file.name;
        file.fileName = fileName;
        return fileName;
    };
    var getFileExt = function(file) {
        var fileExt = "";
        var fileName = file.target_name || file.name;
        if (fileName) {
            var lastDotIdx = fileName.lastIndexOf(".");
            if (lastDotIdx >= 0) {
                fileExt = fileName.substring(lastDotIdx, fileName.length).toLowerCase();
            }
        }
        file.fileExt = fileExt;
        return fileExt;
    };
    var _createUploader = function() {
        if (arguments.length > 0) {
            var args = arguments[0];
            var options = {};
            var callback = SOP.noop;
            if (args.length == 0) {
                options = {};
                callback = SOP.noop;
            } else if (args.length == 1) {
                if (SOP.isObject(args[0])) {
                    options = args[0] || {};
                } else if (SOP.isFunction(args[0])) {
                    callback = args[0] || SOP.noop;
                }
            } else if (args.length >= 2) {
                if (SOP.isObject(args[0])) {
                    options = args[0] || {};
                }
                if (SOP.isFunction(args[1])) {
                    callback = args[1] || SOP.noop;
                }
            }
            if (SOP.isFunction(callback)) {
                if (SOP.isUndefined(options) || !SOP.isObject(options)) {
                    options = {};
                }
                options.dir = options.dir || "temp";
                options.onInited = options.onInited || SOP.noop;
                options.onUploadProgress = options.onUploadProgress || SOP.noop;
                options.onBeforeUpload = options.onBeforeUpload || SOP.noop;
                options.onFileUploaded = options.onFileUploaded || SOP.noop;
                options.onError = options.onError || SOP.noop;
                var dir = options.dir || "temp";
                if (SOP.isString(dir)) {
                    dir = (dir.trim() || "");
                    if (dir.indexOf("/") == 0) {
                        dir = dir.substr(1);
                    }
                    if (dir.lastIndexOf("/") != dir.length - 1) {
                        dir += "/";
                    }
                } else {
                    dir = "";
                }
                getSign(function() {
                    if (!options.filesSelector) {
                        options.filesSelector = "filesSelector";
                        if (!SOP.$(options.filesSelector)) {
                            var filesSelector = document.createElement("a");
                            filesSelector.id = options.filesSelector;
                            filesSelector.style.display = "none";
                            document.body.appendChild(filesSelector);
                        }
                    }
                    var uploader = new plupload.Uploader({
                        runtimes: "html5,flash,silverlight,html4",
                        browse_button: options.filesSelector,
                        multi_selection: (function() {
                            if (SOP.isDefined(options.multiSelection)) {
                                return options.multiSelection;
                            } else {
                                return true;
                            }
                        })(),
                        filters: options.filters || {},
                        //runtimes : 'flash',
                        drop_element: document.getElementById(options.dropContainer || "dropContainer"),

                        container: document.getElementById(options.uploadContainer || "uploadContainer"),
                        flash_swf_url: "https://shejicdn.kuaimai.com/static/plupload_Moxie.swf",
                        silverlight_xap_url: "https://shejicdn.kuaimai.com/static/plupload_Moxie.xap",
                        url: options.cdnUrl ? options.cdnUrl : url,
                        multipart_params: {
                            "Filename": "${filename}",
                            "key": dir + (options.key || SOP.guid()),
                            "policy": policy,
                            "OSSAccessKeyId": accessKeyId,
                            "success_action_status": "200", //让服务端返回200,不然，默认会返回204
                            "signature": signature
                        },
                        init: {
                            PostInit: function() {
                                if (options.filesPoster) {
                                    document.getElementById(options.filesPoster).onclick = function() {
                                        uploader.upload();
                                        return false;
                                    };
                                }
                                if (SOP.isFunction(options.onInited)) {
                                    options.onInited();
                                }
                            },
                            FilesAdded: options.onFilesAdded,
                            UploadProgress: options.onUploadProgress,
                            BeforeUpload: function(uploader, file) {
                                var fileName = getFileName(file);
                                var fileExt = getFileExt(file);
                                if (fileName) {
                                    SOP.extend(uploader.settings.multipart_params, {
                                        "Filename": fileName
                                    });
                                }
                                if (fileExt) {
                                    SOP.extend(uploader.settings.multipart_params, {
                                        "key": (dir + (options.key || SOP.guid())) + fileExt
                                    });
                                }
                                file.key = uploader.settings.multipart_params.key;
                                if (SOP.isFunction(options.onBeforeUpload)) {
                                    options.onBeforeUpload(uploader, file);
                                }
                            },
                            FileUploaded: function(uploader, file, info) {
                                getUrl(file.key, function(url) {
                                    file.url = url;
                                    if (SOP.isFunction(options.onFileUploaded)) {
                                        options.onFileUploaded(uploader, file, info);
                                    }
                                });
                            },
                            Error: options.onError
                        }
                    });
                    uploader.upload = function() {
                        getSign(function() {
                            uploader.settings.url = options.cdnUrl ? options.cdnUrl : url;
                            SOP.extend(uploader.settings.multipart_params, {
                                policy: policy,
                                OSSAccessKeyId: accessKeyId,
                                success_action_status: "200", //让服务端返回200,不然，默认会返回204
                                signature: signature
                            });
                            uploader.start();
                        });
                    };
                    uploader.init();
                    callback(uploader);
                });
            }
        }
    };
    SOP.DefaultClient.prototype.createUploader = function() {
        client = this;
        var args = arguments;
        if (false === jsLoaded) {
            queue.push(args);
            SOP.use("plupload", function() {
                jsLoaded = true;
                if (queue.length > 0) {
                    for (var idx in queue) {
                        var args = queue.shift();
                        _createUploader(args);
                    }
                }
            });
        } else {
            _createUploader(args);
        }
    };
})();


(function () {
    /**
     * 图库图片key 转化为url
     */
    SOP.extend({
        FileUrlsGetRequest: function () {
            return this;
        }
    });
    SOP.FileUrlsGetRequest.prototype = {
        keys: null,                // 需要转化的key
        styles: null,//图片样式
        method: "idpt.file.urls.get",
        version: "1.0",
        check: function () {
            if (null === this.version || "" === this.version.trim()) {
                throw new Error("The argument 'version' can't be empty.");
            }
        }
    };
})();

(function () {
    /**
     * 87、获取OSS请求签名
     */
    SOP.extend({
        OSSSignGetRequest: function () {
            return this;
        }
    });
    SOP.OSSSignGetRequest.prototype = {
        method: "idpt.oss.sign.get",
        version: "1.0",
        check: function () {
            if (null === this.version || "" === this.version.trim()) {
                throw new Error("The argument 'version' can't be empty.");
            }
        }
    };
})();
